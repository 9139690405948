import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import '../node_modules/bootstrap/scss/bootstrap.scss';
import './scss/style.scss';
import './css/materialdesignicons.min.css';

const LandingPage = React.lazy(() => import('./js/components/pages/landing'));

const app = (
  <BrowserRouter>
    <Suspense fallback={<span />}>
      <Switch>
        <Route exact path='/' component={LandingPage}></Route>
        <Route path='*'><Redirect to={{pathname:'/', search:window.location.search}} /></Route>
      </Switch>
    </Suspense>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();